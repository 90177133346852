import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import { Container } from "../components/global";

import Header from "../components/sections/terms-privacy/header";
import Footer from "../components/sections/footer";

import { Content, Section, P, H1, H2, UL } from "../styles/TCandPrivacyStyles";

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />

    <Container>
      <Content>
        <Section>
          <H1>Welcome to OPay!</H1>
          <P>
            The OPay service, website and/or software application (collectively, the{" "}
            <a href="/service-catalog">“Service”</a>) enables you to transfer funds between your mobile money wallet and your bank account, as well as to the wallet/account of another user. It also enables you, through your supported mobile money wallet, account or debit/credit card, to pay for products and services offered by third parties (“Products”). The Service is operated by OPay Digital Services Limited (“OPay”), subject to these Terms of Service (“Terms”).
          </P>
        </Section>

        <Section>
          <H2>
            <span>Terms of service</span>
          </H2>
          <P>
            <span>This is a contract.</span> 
            These Terms constitute a contract between you and OPay. If you do not accept these Terms, please do not use the Service. By using any part of the Service, you accept these Terms. If you are under eighteen (18) years of age, you must not use the Service.
          </P>
          <P>
            <span>You must register a wallet account with valid information.</span>
            To use the Service, you must: (a) provide a valid mobile phone number; (b) agree that an account associated with such number be created; (c) accept the current Terms; and (d) agree to submit such other (and additional) information as OPay may request in line with regulatory/legal provisions. You agree to provide true, accurate and complete information about yourself as prompted by OPay during the account registration process. You must ensure that any third-party information you provide to OPay has been authorized appropriately. You will be given the option of receiving a unique PIN for purposes of reusing your account. You are responsible for keeping your PIN secure at all times, without reservation. For the avoidance of doubt, OPay will not be responsible when/where you compromise your details by disclosing it to third parties.
          </P>
          <P>
            <span>Third-party merchants offer the goods & services.</span>
            The Service provides an opportunity to discover offers from independent third-party merchants (“Merchants”). These Merchants sell their respective Products subject to their own terms and conditions. To illustrate:
            <UL>
              <li>
                <span>Airtime: </span>If you choose to purchase pre-paid airtime, you do so pursuant to the relevant terms and conditions of your mobile telecommunications provider.
              </li>
              <li>
                <span>Utilities: </span>If you choose to purchase pre-paid electricity or water, you do so pursuant to the relevant terms and conditions of the utility provider.
              </li>
              <li>
                <span>USSD Subscription Service: </span>If you subscribe to the USSD Service, you agree that OPay may charge you the attendant fees related to the service, and pursuant to the relevant terms and conditions of your mobile telecommunications provider.
              </li>
            </UL>
          </P>
          <P>
          If you decide to purchase Products such as these from third party Merchants, you do so at your own risk and subject to the relevant Merchant’s terms and conditions. We are unable to investigate, monitor, or check the products for accuracy, reliability, appropriateness or completeness. By granting you access to discover the Products via the Service, OPay does not imply approval or endorsement of such Products. You therefore agree that OPay is not responsible or liable for the Products you purchase from Merchants.
          </P>
          <P>
            <span>Payments are processed by third parties.</span>In order to facilitate your purchases from Merchants, OPay has integrated its Service with the billing solutions of certain mobile money providers and credit card issuing banks (“Payment Processors”). When you purchase a Product from a Merchant using your supported mobile money wallet or account or debit/credit card, payments will be processed by such Payment Processors. Payment Processors have their own terms and conditions governing your use of their payment services. You understand and agree that OPay does not process payments and is not responsible or liable for any transactions in which you engage. By engaging in a purchase transaction using your mobile money account or debit/credit card, you authorize the Merchant (or OPay on its behalf) to bill your account or card for the purchase amount.
          </P>
          <P>
            <span>Your privacy is important to us.</span>OPay takes the matters of protection and security of its users’ information very seriously. OPay’s privacy policy governing the Service is attached to these Terms (“Privacy Policy”). The Privacy Policy is incorporated into these Terms by this reference. By using the Service, you agree to the use of your data under OPay’s Privacy Policy. The Privacy Policy addresses only the personal information processed by OPay in providing the Service to you. Merchants and Payment Processors have their own information-gathering practices which apply when you choose to purchase their Products.
          </P>
          <P>
            <span>Product inquiries should be submitted to the relevant Merchant.</span>
            If you have any questions, concerns or requests related to a Product you have purchased from a Merchant, please contact the Merchant directly or contact us at{" "}
            <a href="mailto:ng-support@opay-inc.com">ng-support@opay-inc.com</a> for assistance. Each Merchant maintains its own customer contact desk for handling such requests, however OPay will do its best to assist you. If you wish to submit a complaint about the practices of a Merchant offering its Products via the Service, you may contact us by email at <a href="mailto:ng-support@opay-inc.com">ng-support@opay-inc.com</a>.
          </P>
          <P>
            <span>You must not use the Service to violate any laws. </span>
            You must not use the Service to violate or infringe the rights of any other person, including the rights of other users, OPay’s rights in the Service or Merchants’ rights in their Products. You must not breach any laws or regulations when using the Service or attempt to disrupt or interfere with the security or functionality of the Service. In the event that OPay reasonably suspects that you are using the Services for illegal activities (particularly fraud, money laundering or other related illegalities), OPay reserves the right to block your account immediately without liability. Such illegalities as mentioned above may be as a result of enacted laws, court judgments, rulings or pronouncements, or regulatory policies and guidelines to which OPay is bound. If a breach is associated with your account/wallet, you agree that we have the right to apply restrictions to your account/wallet and report to the appropriate law enforcement agencies or the respective regulator, in line with extant laws without informing you.
          </P>
          <P>
            <span>OPay may contact you regarding your account or the Service. </span>
            You expressly agree that, as part of the Service, you may, from time to time, receive communications from OPay via email, instant message, telephone, text message (SMS), emails or other means. You may elect to stop receiving promotional messages by emailing your request to opt-out, along with your cell phone number, to{" "}
            <a href="mailto:ng-support@opay-inc.com">ng-support@opay-inc.com</a>, or following the opt-out instructions in the message. Even if you choose to opt out of receiving promotional messages, you may not opt out of receiving service-related messages as these ensure that we are able to deliver accurate, relevant, sensitive and security-related services to you.
          </P>
          <P>
            <span>OPay may discontinue the Service. </span>
            OPay may, in furtherance of regulatory, time-sensitive and security-related purposes, terminate your access to the Service or discontinue providing the Service or any part of the Service, with due notice to you [or without notice where the suspension or termination is expedient to forestall, curb or extinguish some ongoing fraud, industry-wide compromise or an ongoing financial crime-related investigation]. Rest assured that we will provide as much notice as the circumstance allows, and restore the Service at the earliest convenience. You agree that in the event of the foregoing, OPay will not be responsible or liable to you or any third party.
          </P>
          <P>
            <span>OPay is not liable for any damages you may incur as a result of using the Services. </span>
            In no event shall OPay or its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential damages, or any damages whatsoever arising out of, or in any way connected with the use or performance of the service, with the delay or inability to use the service, the provision of (or failure to provide services), or otherwise arising out of the use of the service, whether based on contract, tort, negligence, strict liability, or otherwise, even if OPay or any of its suppliers have been advised of the possibility of such damages.
          </P>
          <P>
            <span>You agree to mediate any disputes.</span>
            This Agreement is subject to, and shall be governed by, and construed in accordance with the laws of Nigeria, without reference to the principles of conflict of laws thereof. Any matters arising concerning the interpretation, validity or implementation of this Agreement not solved by mutual agreement between the Parties shall be submitted to mediation in the English language before a sole mediator to take place in Lagos, Nigeria, unless impractical to do so. Nothing in this Agreement will be deemed as preventing OPay from seeking injunctive relief (or any other provisional remedy) from any court having jurisdiction over the Parties and the subject matter of the dispute as is necessary to protect OPay’s name, proprietary information, trade secrets, know-how, or any other intellectual property rights.
          </P>
          <P>
            <span>OPay may modify these Terms. </span>We may modify these Terms and related policies (including, but not limited to the Privacy Policy) at any time in the future. Where this happens, we will communicate the changes to you. By continuing to use the Service, you agree to be bound by the latest version of these Terms. It is your responsibility to familiarize yourself with the communicated changes.
          </P>
          <P>
            <span>Alteration. </span>No alteration, variation or agreed cancellation of this agreement, and the Privacy Policy, shall be of any effect unless so directed by us.
          </P>
          <P>
            <span>Whole Agreement. </span>This Agreement constitutes the whole agreement between the parties in regard to the subject matter hereof and no warranties or representations of any nature whatsoever other than set out in this agreement have been given by any of the parties.
          </P>
          <P>
            <span>Waiver/Relaxation. </span>No relaxation or indulgence which OPay may show to you shall in any way prejudice or be deemed to be a waiver of its rights hereunder.
          </P>
          <P>
            <span>Survival. </span>Each and every provision of this Agreement (excluding only those provisions which are essential at law for a valid and binding Agreement to be constituted) shall be deemed to be separate and severable from the remaining provisions of this Agreement. If any of the provisions of this Agreement (excluding only those provisions which are essential at law for a valid and binding Agreement to be constituted) is found by any court of competent jurisdiction to be invalid and/or unenforceable then, notwithstanding such invalidity and/or unenforceability, the remaining provisions of this Agreement shall be (and remain) of full force and effect.
          </P>
        </Section>

        <Section>
          <H2>
            <span>TERMS AND CONDITIONS FOR SAVING PRODUCTS</span>
          </H2>
          <P>
            <span>Effective Date: December 9, 2021</span>
            <br />
            <span>Please read carefully before using this service.</span>
            <br />
          </P>
          <P>
          The Saving Products service, website and/or software application (collectively, the “Service”) enables you to receive our online money services in accordance with our products under these Saving Products Terms and Conditions (the “Terms”). These Terms is a bond agreement between Blueridge Microfinance Bank Limited, a Microfinance Bank registered under the Laws of the Federal Republic of Nigeria having its registered office at No.60 Adeniyi Jones , Ikeja, Lagos (“we”,”us”, “Saving Products”); and you as our customer, holding a valid BVN, and being entitled to receive financial services under these Terms.
          </P>
          <br />
          <P>
            <span>1. THIS IS A CONTRACT</span>
          </P>
          <P>
          These Terms constitute a contract between you and Saving Products. Please do not use the Service if you do not accept these Terms. By using any part of the Service you accept these Terms. If you are under eighteen (18) years of age, you may not use the Service.
          </P>
          <P>
            <span>2. ACCOUNT REGISTRATION</span>
          </P>
          <P>
            <span>You must register an account with valid information.</span> To use the Service, you must: (a) provide a valid mobile phone number; (b) agree to the creation of an account associated with such number; (c) accept the present Terms; and (d) submit such other and additional information as Saving Products may request during registration. You agree to provide true, accurate and complete information about yourself as prompted by Saving Products during the account registration process. You will be given the option of receiving a unique PIN for purposes of reusing your account. You are responsible for keeping your PIN secure. Your personal information is highly protected in accordance with our Privacy Policy published and updated on our platform.The Saving Products product shall only be made available to you once our Know Your Customer process has been complied with by you and your identification has been verified.
          </P>
          <P>
            <span>3. SERVICES DISCRIPTION</span>
          </P>
          <P>
            <span>Various sub-products:</span> Under our Saving Products product, we may provide various kinds of sub-products for your choice to deposit investment amount and earning interests, including but not limited to OWealth, Fixed, and Targets, collectively we refer to as “Saving Products”. Such sub-products contain flexible interests, fixed interests, a combination  of both flexible and fixed interests or any other such method. You can find our various sub-products on the pages of our platform and choose one or more that suit to you. For detailed information on each sub-product, please refer to Q & A, sub-products policies or any such information on our platform. All written information shall be regarded as an internal part of this terms and conditions.
          </P>
          <P>
            <span>
            Deposit with interests: There are two major types of deposit modes provided to you, Term deposit or Demand deposit. But we do have various sub-types of such deposit modes, for details you can refer to specific pages on our platform No matter which of the above modes you choose
            </span>
            , you can deposit your money electronically into the account opened in our Platform to receive our Saving Products services, and receive interest according to interest rate we offered on our platform. Under the{" "}
            <span>Term Deposit</span> mode, you can only and are obliged to redeem such investment within applicable days (the “investment term”) after investment. Under the <span>Demand Deposit</span> mode, you are at liberty to redeem such investment any time after investment, but the redemption day will have no interest.
          </P>
          <P>
            <span>Withdrawal: </span>You are entitled to withdraw your deposited money anytime in accordance with our withdrawal policies of each sub-products published on our Platform or send to you via other methods.
          </P>
          <P>
            <span>Liquidate: </span>Means you withdrawal your investment amount with the calculation of applicable interests. You are entitled to liquidate your investment amount according to liquidation policies of each investment product.
          </P>
          <P>
            <span>Consumption: </span>You can use your Saving Products balance for any applicable consumption, whether online or offline.
          </P>
          <P>
            <span>Transfer: </span>You can transfer your Saving Products balance to your own bank accounts, others’ bank accounts, other users’ OPay wallet account or any account that is supported by our APP and Platform.
          </P>
          <P>
            <span>4. AUTOMATIC TRANSFER AND INVESTMENT</span>
          </P>
          <P>
          You can choose automatic transfer (Automatic Transfer) under these Terms by ticking the box “Accept Automatic Investment ” and clicking the button of “Turn on Auto Invest”. By clicking, You authorize Saving Products to deduct ALL balance from your OPay APP wallet and invest FULL amount of such deduction in Saving Products. By activating the Automatic Transfer, you agree to Saving Products to send deduction instructions to OPay APP and such instructions shall be deemed as your own instructions. You shall be responsible for the result of such instructions.
          </P>
          <P>
          You warrant and represent that the balance is legitimately owned by you and that the acceptance of this Agreement is your true  and voluntary decision.
          </P>
          <P>
          You understand and agree that, in any circumstance that Saving Products did not send deduction instructions or failed to successfully deduct your balance for investment, Saving Products will not be liable or responsible for any loss arising from such failure.
          </P>
          <P>
          You understand and agree that, due to deduction of your balance and the corresponding investment, your balance in OPay APP wallet may become insufficient to support your other demands which may cause loss (including failing to make other investments and the loss of earnings), and Saving Products will not be liable or responsible for any loss of such.
          </P>
          <P>
          You can also terminate this Agreement at any time by clicking the button  “Turn off Auto Invest”. Termination of this Agreement will not affect the validity and effectiveness of deductions and investments made before such termination.
          </P>
          <P>
          You might also be entitled to invest certain amount to Saving Products balance by deducting the balance of your debit card or through bank transfer once applicable.
          </P>
          <P>
            <span>5. INTEREST</span>
          </P>
          <P>
            Any interest shall only be payable on funds that have remained in your Saving Products Wallet for a period
            of not less than one complete working day ( i.e 24 (twenty four) working day hours), if no specific sub-products’
            policies against, and which interest shall be at a rate determined by Saving Products from time to time and
            payable pursuant to the amount shown on the application.
          </P>
          <P>
            You acknowledge and agree that Saving Products shall have the right to amend the interest rate applicable to
            the Saving Products product and you hereby indemnify and waive any/all claims of any nature against Saving
            Products for interest on your Saving Products product.
          </P>
          <P>
            Your interest rate might vary{" "}
            <u>
              according to the amount you invest, the period you kept, and the sub-products you choose. We highly
              recommend you to check your products, plan pages to check your interest exactly. You fully understand that
              you shall pay for any taxes due to your interest earnings, including but not limited to Withholding Tax.
            </u>
          </P>
          <P>
            We confirm that the interest we offer to you shall be in compliance with applicable laws and regulations. In
            case that any change for the laws and regulations results in the decrease of your interests received, we are
            not obligated to compensate for it.
          </P>
          <P>
            <span>6. ANTI-FRAUD</span>
          </P>
          <P>
            You agree and acknowledge that where the funds in your Saving Products wallet have been flagged as or are
            suspected of being fraudulent in nature, Saving Products may immediately suspend your account and retain the
            funds pending an investigation. If you elect to lock an amount in your Saving Products wallet, such lock
            will remain in place for an agreed period time. If you elect to lift the lock on the account prior to the
            agreed date, you agree that Saving Products may impose a penalty on you for lifting such lock and which
            amount Saving Products may immediately deduct from your Saving Products wallet balance. Interest accrued on
            your Saving Products wallet will be calculated annually and credited daily. If you wish to withdraw funds
            from your Saving Products Wallet, such funds shall only be transferred to your Saving Products Wallet. If
            you are a registered KYC customer of level 1 or higher, you will have the option to have the funds in your
            Saving Products Wallet transfer automatically to your Saving Products account on a daily basis. You
            irrevocably agree that where you select the option to transfer funds from your Saving Products Wallet
            balance to your Saving Products account and such transfer shall take place daily at a time designated by Us. The
            automatic transfer can be switched off at any time by you. At our discretion, you may be granted the option
            to effect payment for other Saving Products services (as designated by Saving Products from time to time)
            with funds held in your Saving Products wallet.
          </P>
          <P>
            <span>7. NO WARRANTIES</span>
          </P>
          <P>
          The Service is provided without any warranties or guarantees. THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. Saving Products AND ITS SUPPLIERS AND AFFILIATES DISCLAIM ALL WARRANTIES WITH REGARD TO THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE, OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE.
          </P>
          <P>
            <span>8. NOT LIABLE FOR DAMAGES</span>
          </P>
          <P>
          Saving Products is not liable for any damages you may incur as a result of using the Services. IN NO EVENT SHALL BLUE RIDGE OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SERVICE, WITH THE DELAY OR INABILITY TO USE THE SERVICE, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THE SERVICE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF OWEALTH OR ANY OF ITS SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </P>
          <P>
            <span>9. DISPUTES AND ARBITRATION</span>
          </P>
          <P>
            <span>You agree to arbitrate any disputes.</span> This Agreement is subject to, and shall be governed by, and construed in accordance with the laws of Nigeria, without reference to the principles of conflict of laws thereof. Any matters arising concerning the interpretation, validity or implementation of this Agreement not solved by mutual agreement between the Parties shall be submitted to arbitration in the English language before a sole arbitrator to take place in Lagos, Nigeria as the seat of the arbitration. The arbitration shall be conducted pursuant to the Rules of Arbitration of the Chartered Institute of Arbitrators of the United Kingdom. The arbitral decision shall be final and binding on the Parties and may be made an order of court. The Parties unconditionally consent and submit to the jurisdiction of the High Court of Lagos, Nigeria for such purpose. Nothing in this Agreement will be deemed as preventing Saving Products from seeking injunctive relief (or any other provisional remedy) from any court having jurisdiction over the Parties and the subject matter of the dispute as is necessary to protect Saving Products’s name, proprietary information, trade secrets, know-how, or any other intellectual property rights.
          </P>
          <P>
            <span>10. ENFORCEMENT</span>
          </P>
          <P>
            If arbitration, a court of competent jurisdiction or other competent authority finds any part of this
            Agreement invalid, unlawful or unenforceable, then such part shall be severed from the remainder of this
            Agreement which will continue to be valid and enforceable to the fullest extent permitted by law.
          </P>
          <P>
          <span>11. NO AGENCY</span>
        </P>
        <P>
          Neither Party is authorised to act as an agent for, or legal representative of, the other party and neither
          Party shall have the authority to assume or create any obligation on behalf of, in the name of, or binding
          upon the other Party, save and except with the express consent in writing of the other Party.
        </P>

        <P>
        <span>12. MODIFICATION</span>
      </P>
      <P>
        <span>Saving Products may modify these Terms.</span> These Terms and related policies (including but not
        limited to the Privacy Policy) may be modified by Saving Products without notice at any time in the future.
        Changes will be posted. By using the Service, you agree to be bound by the latest version of these Terms. It
        is your responsibility to remain informed of any changes.
      </P>

          <P>
            <span>13. OTHERS</span>
          </P>
          <P>
            <UL>
              <li>
                <span>Alteration.</span> No alteration, variation or agreed cancellation of this agreement, and this
                product, shall be of any effect unless directed so by us.
              </li>
              <li>
                <span>Binding</span> Any decision, exercise of discretion, judgement or opinion or approval of any
                matter mentioned in this Agreement or arising from it shall be binding on the parties only if in writing
                unless otherwise expressly provided in this Agreement.
              </li>
              <li>
                <span>Notice</span> Any notice pursuant to this Agreement shall be given by fax, electronic mail or
                letter and the onus of confirmation of receipt of such notices shall be on the sender.
              </li>
              <li>
                <span>Whole Agreement</span> This Agreement constitutes the whole agreement between the parties in
                regard to the subject matter hereof and no warranties or representations of any nature whatsoever other
                than set out in this agreement have been given by any of the parties.
              </li>
              <li>
                <span>Waiver/Relaxation</span> No relaxation or indulgence which Saving Products may show to you shall
                in any way prejudice or be deemed to be a waiver of its rights hereunder. No term shall survive at the
                expiry or termination of this Agreement unless expressly provided. No delay or failure by either party
                to exercise any of its powers, rights or remedies under this Agreement will operate as a waiver of them,
                nor will any single or partial exercise of any such powers, rights or remedies preclude any other or
                future exercise of them.
              </li>
              <li>
                <span>Survival</span> Each and every provision of this Agreement (excluding only those provisions which
                are essential at law for a valid and binding Agreement to be constituted) shall be deemed to be separate
                and severable from the remaining provisions of this Agreement. If any of the provisions of this
                Agreement (excluding only those provisions which are essential at law for a valid and binding Agreement
                to be constituted) is found by any court of competent jurisdiction to be invalid and/or unenforceable
                then, notwithstanding such invalidity and/or unenforceability, the remaining provisions of this
                Agreement shall be and remain of full force and effect.
              </li>
              <li>
                <span>Confidential Information</span> Each Party shall keep confidential, any information concerning the
                other Party, which comes to its knowledge by way of obtaining, accessing, receiving or learning such
                information as a result of the provision of the Services or pursuant to this Agreement
                <br />
                Confidential Information shall mean non-public information that a Party designates as being proprietary
                or confidential or which by its nature or the circumstances surrounding its disclosure reasonably ought
                to be treated as confidential. For the purposes of this clause 20, where a Party makes confidential
                information available to the other Party it shall be referred to as the “Disclosing Party” and the Party
                receiving such information shall be referred to as the “Receiving Party”. Confidential Information
                includes, without limitation and in whatever format, information relating to the Disclosing Party’s
                software products, source code, API data files, documentation, specifications, databases, networks,
                system design, file layouts, tool combinations, development methods, consulting methodologies, user
                manuals and documentation, training materials (computer-based or otherwise), schema, flow charts,
                prototypes and evaluation copies of any of the foregoing, as well as information relating to the
                Disclosing Party’s business or financial affairs, which may include business methods, marketing
                strategies, pricing, competitor information, product development strategies and methods, customer lists
                and customer information and financial results.
              </li>
              <li>
                <span>Force Majeure</span> if either Party is rendered unable, wholly or in any material part, by reason
                of force majeure to carry out its obligation under this Agreement, then on such Party giving notice and
                particulars in writing to the other Party within seven (7) days or such other reasonable time after the
                occurrence of the cause relied upon, such obligations shall be suspended.
                <br />
                The term “Force Majeure”, as employed in this Agreement, shall mean acts of God, strikes, lockouts or
                other disturbances, acts of public enemies, wars, blockages, insurrections, riots, epidemics, fires,
                floods or any governmental prohibitions issued by any legitimate authority, including restrains of rules
                and people, civil disturbances and any other cause not within the control of the Parties. If the period
                of the Force Majeure continues for more than thirty (30) days then the Parties shall decide the
                appropriate steps to be taken at a meeting summoned for that purpose.
              </li>
            </UL>
          </P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);

export default TermsAndConditions;

const pageData = {
  headerData: {
    captionText: "Terms & Conditions",
    subTitleText: "Version 3.0 | May 28, 2024",
  },
};
